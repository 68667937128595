<template>
    <div>
        <h2>Redirecionando...</h2>
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "Oauth2CallbackPage",
    async mounted() {
        let dataLegacy = this.$route.query?.data
        const data = this.$route.query
        const state = data.state

        // Verificação para o legado e o novo
        const hasDataValidation = !dataLegacy || !state
        if (!hasDataValidation) {
            EventBus.emit("message", {
                type: "danger",
                message: "Erro ao autorizar conta: Dados de retorno inválidos"
            })
            return this.$router.push("/service-accounts")
        }

        try {
            //Oauth2 Legado
            if (dataLegacy) {
                dataLegacy = JSON.parse(atob(dataLegacy))
                const serviceAccountId = dataLegacy?.service_account_id
                await Api.serviceAccounts.updateOauth2(serviceAccountId, {
                    ...dataLegacy,
                    grant_type: "authorization_code"
                })
            }

            //versão nova do oAuth2
            if (state) {
                const serviceAccountId = state.split("_")[0]
                await Api.serviceAccounts.updateOauth2(serviceAccountId, {
                    ...data,
                    grant_type: "authorization_code"
                })
            }

            EventBus.emit("message", {
                type: "success",
                message: "Conta autorizada com sucesso!"
            })
        } catch (err) {
            const error_message =
                err.response.data.message ??
                err.response.data.error ??
                err.response.data

            EventBus.emit("message", {
                type: "danger",
                message: "Erro ao autorizar conta: " + error_message
            })
        }

        this.$router.push("/service-accounts")
    }
}
</script>
